<template>
  <div style="padding: 40px;">
    <v-card>
      <div style="padding: 10px 20px;">
        <h2>Vista previa</h2>
      </div>
      <v-tabs
        v-model="tab"
        centered
        background-color="primary"
        dark
      >
        <v-tab
          v-for="(item, index) in tabs"
          :key="index"
        >
          <v-icon style="margin-right: 5px;" small>{{item.icon}}</v-icon> {{ item.name }}
        </v-tab>
      </v-tabs>
      <div 
        v-if="tabs && tab!==null"
        :key="tabs[tab].id"
      >
        <div
          v-if="tabs[tab].type === 'message'"
          style="padding: 40px;text-align: center;"
        >
          <h1>NO DISPONIBLE</h1>
        </div>
        <div
          v-if="tabs[tab].type === 'mainMeetingRoom'"
          style="padding-bottom: 40px;"
        >
          <meeting-room-preview
            :workspaceID="workspaceID"
          />
        </div>
        <div
          v-else-if="tabs[tab].type === 'event'"
          style="padding-bottom: 40px;"
        >
          <event-preview
            :id="tabs[tab].id"
            :show="tabs[tab].show"
          />
        </div>
        <div
          v-else-if="tabs[tab].type === 'activityBarcelo01'"
          style="padding-bottom: 40px;"
        >
          <activity-barcelo01-preview
            :contentTypeID="tabs[tab].id"
            locale="es"
            color="red"
          />
        </div>
        <div
          v-else-if="tabs[tab].type === 'activityBarcelo'"
          style="padding-bottom: 40px;"
        >
          <activity-barcelo-preview
            :workspaceID="workspaceID"
            locale="es"
            color="red"
          />
        </div>
        <div
          v-else
        >
          <rss-preview
            :playLoopConfig="tabs[tab]" 
            locale="es"
          />
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import api from '@/services/api'
import MeetingRoomPreview from './MeetingRoomPreview'
import EventPreview from './EventPreview'
import ActivityBarceloPreview from './ActivityBarceloPreview' 
import ActivityBarcelo01Preview from './ActivityBarcelo01Preview' 
import RssPreview from './RssPreview'
export default {
  components: {
    MeetingRoomPreview,
    EventPreview,
    ActivityBarceloPreview,
    ActivityBarcelo01Preview,
    RssPreview,
  },
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
    isMeetingRoom: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    tab: null,
    tabs: []
  }),
  watch: {
    isMeetingRoom () {
      this.handlePrepareTabs()
    },
    workspaceID () {
      this.handlePrepareTabs()
    },
  },
  mounted () {
    this.handlePrepareTabs()
  },
  methods: {
    async handlePrepareTabs () {
      const aux = []
      if (this.isMeetingRoom) {
        aux.push({ id: 'mainMeetingRoom', type: 'mainMeetingRoom', name: 'Sala de reuniones' })
      }
      
      const url = `v1/internal/workspaces/${this.workspaceID}/preview-dashboard`
      aux.push(...await new Promise((resolve) => api.getAllWithoutLimit ('dviewcontent', url)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          resolve([])
        })))

      this.tabs = aux
    },
  },
}
</script>
