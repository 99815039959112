<template>
  <div style="padding: 0 40px">
    <br/>
    <center>
    <div id="event-preview" style="max-width: 800px;">
      <iframe 
        v-if="url"
        class="mr-preview" 
        :src="url" 
        style="width: 100%;margin-top:10px;border: 10px inset gray" 
        :height="height" 
      />
    </div>
    </center>
  </div>
</template>
<script>
//import { mapState } from 'vuex'
//import api from '@/services/api'
//import utils from '@/services/utils'
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      default: 'es',
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    menu: false,
    days: 0,
    url: null,
    height: 1080,
    date: new Date().toISOString().split('T').shift(),
    minDate: new Date().toISOString().split('T').shift()
  }),
  watch: {
    workspaceID () {
      this.getUrl()
    },
    days () {
      this.getUrl()
    },
    date () {
      var fechaInicio = new Date().getTime()
      var fechaFin    = new Date(this.date).getTime()

      const diff = fechaFin - fechaInicio

      this.days = Math.ceil(diff/(1000*60*60*24))
    },
  },
  mounted () {
    window.addEventListener('resize', this.setWidth)
    this.getUrl()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setWidth)
    console.log('beforeDestroy')
  },
  methods: {
    setWidth () {
      this.getUrl()
    },
    getUrl () {
      this.$nextTick(() => {
        const e = document.getElementById('event-preview')
        let width = e ? e.offsetWidth : null
        if (!width) return
        const zoom = ((width-20)/1920)*100
        this.height = (1080 * width)/1920
        //.offsetWidth
        //console.log(width)
        this.url = this.show ? `https://widgets.dviewcontent.com/shows-event-cm/?&cmdviewcontentid=${this.id}&zoom=${zoom}&t=${new Date().toISOString()}` : `https://widgets.dviewcontent.com/activity-week-card/?&cmdviewcontentid=${this.id}&locale=es&addDays=0&zoom=${zoom}&t=${new Date().toISOString()}`
      })
    },
  }
}
</script>
<style scoped>
.mr-preview {
  border: 0;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}
.mr-preview::-webkit-scrollbar {
  display: none; /* For WebKit browsers */
}
</style>

